<template>
	<b-modal ref="modal-export-subscribers" id="modal-export-subscribers" title="Enter Email ID on which you want to receive exported CSV">

		<b-overlay :show="isSendRequestToExportProcess" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">

			<validation-observer ref="siteGeneralForm">

				<b-form @submit.prevent="siteValidationForm" class="p-1">

					<b-form-group label="Enter Email" label-for="email">
						<validation-provider #default="{ errors }" name="email" rules="required|email" :custom-messages="{ required: 'Please enter email', email: 'Please enter valid email'}">
							<b-form-input id="email" type="email" v-model="requestEmail" :state="errors.length > 0 ? false:null" name="email" placeholder="Email Address" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-row>
						<b-col cols="12 pull-left">
							<b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1">
								Send Request
							</b-button>
						</b-col>
					</b-row>

				</b-form>

			</validation-observer>

		</b-overlay>

		<template #modal-footer>
			<div class="w-100">
				<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary" class="float-right" @click="hideModal">
					Close
				</b-button>
			</div>
		</template>

	</b-modal>
</template>

<script>
	import {
		BFormInput, BInputGroup, BModal, VBModal, BButton, BForm, BFormGroup, BOverlay
	} from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import { required } from '@validations'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
		components: {
			BFormInput, BInputGroup, BModal, VBModal, BButton, BForm, BFormGroup, ValidationProvider, ValidationObserver, BOverlay
		},
		directives: {
			Ripple,
		},
		model: {},
		props: {
			prop_contactListObject: {
				type: Object,
				default: () => { },
			}
		},
		watch: {
			prop_contactListObject(newVal) {
				this.contactListId = newVal.contact_list_id
			}
		},
		computed: {},
		created: function () {
			this.$store.commit('app/TOGGLE_OVERLAY', true); /* FOR BLUR BACKEND */
			//this.fetchSitesAndUsers();
		},
		data() {
			return {
				isSendRequestToExportProcess: false,
				requestEmail: '',
				contactListId: '',
				required,
			}
		},
		methods: {
			hideModal() {
				this.prop_contactListObject = {};
				this.requestEmail = '';
				this.contactListId = '';
				this.isSendRequestToExportProcess = false;
				this.$refs['modal-export-subscribers'].hide()
				this.$emit('hide-modal-export-subscribers', true);
			},
			siteValidationForm() {
				this.$refs.siteGeneralForm.validate().then(success => {
					if (success) {
						this.isSendRequestToExportProcess = true;
						this.$contactAPI.post('send-export-subscribers-request', {
							'auth_id': this.$root.authUserObject.id ?? '',
							'contact_list_id': this.contactListId ?? '',
							'email': this.requestEmail ?? '',
						}).then((response) => {
							response = response.data;
							if (response.status) {
								this.hideModal();
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'success',
										text: response.message,
									},
								});
								this.$emit('refreshSiteList', true);
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'danger',
										text: response.message,
									},
								});
							}
						});
					}
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
</style>