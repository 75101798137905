<template>
	<div>
		<user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :prop_editContactListObject="editContactListObject" @refetch-data="getContactList()" />
		
		<!-- Table Container Card -->
		<b-card no-body class="mb-0">
			<b-overlay :show="isFetchingContactListArray" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
				<div class="m-2">
					<!-- Table Top -->
					<b-row>
						<!-- Search -->
						<b-col cols="12" md="12">
							<div class="d-flex align-items-center justify-content-end">
								<b-button variant="primary" @click="addContactList()">
									<span class="text-nowrap">Add New Contact List</span>
								</b-button>
							</div>
						</b-col>
					</b-row>
				</div>
				<b-table ref="refUserListTable" class="position-relative p-1" :items="contactListArray" responsive :fields="tableColumnsArray" primary-key="id" show-empty empty-text="No records found">
					<template #cell(list_name)="data">
						<b-link :to="{ name:'contacts-list-subscribers', params: { contact_list_id: data.item.contact_list_id } }">{{ data.item.list_name }}</b-link>
					</template>

					<template #cell(store_type)="data">
						<span v-if="data.item.store_type == 1">In our site</span>
						<span v-if="data.item.store_type == 2">Drip Account</span>
					</template>

					<template #cell(total_contacts)="data">
						<feather-icon v-if="data.item.total_contacts == 'loading'" icon="LoaderIcon" size="18" class="spinner" />
						<div v-else>{{ data.item.total_contacts }}
						</div>
					</template>

					<!-- Column: Actions -->
					<template #cell(actions)="data">
						<div class="text-nowrap">
							<feather-icon v-if="data.item.total_contacts == 'loading'" icon="EditIcon" size="21" class="mx-1 text-muted" />
							<feather-icon v-else icon="EditIcon" size="21" class="mx-1 cursor-pointer" @click="editContactList(data.item)" />
							<feather-icon icon="TrashIcon" size="21" class="mx-1 cursor-pointer" @click="deleteContactList(data.item.contact_list_id)" />
							<feather-icon v-if="data.item.total_contacts != 0" icon="DownloadIcon" size="21" class="mx-1 cursor-pointer" @click="openExportSubscribersModal(data.item)" />
						</div>
					</template>
				</b-table>
			</b-overlay>
		</b-card>

		<export-subscribers-request-modal :is-export-subscribers-request-active.sync="isExportSubscribersRequestActive" :prop_contactListObject.sync="exportContactListObject" @hide-modal-export-subscribers="hideModalExportSubscribers" /> <!-- FOR EXPORT -->

	</div>
</template>

<script>
	import {
		BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BOverlay, BModal
	} from 'bootstrap-vue'
	import vSelect from 'vue-select'
	import store from '@/store'
	import { ref, onUnmounted } from '@vue/composition-api'
	import Ripple from 'vue-ripple-directive'
	import useUsersList from './useUsersList'
	import userStoreModule from '../userStoreModule'
	import UserListAddNew from './ContactListAddNew.vue'
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
	import ExportSubscribersRequestModal from '../ExportSubscribersRequestModal.vue'

	export default {
		components: {
			UserListAddNew,
			BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BOverlay, BModal,
			vSelect,
			ExportSubscribersRequestModal
		},
		directives: {
			Ripple,
		},
		data() {
			return {
				isFetchingContactListArray: false,
				isAddNewUserSidebarActive: false,
				editContactListObject: {},
				exportContactListObject: {},
				isExportSubscribersRequestActive: false,
				tableColumnsArray: [
					{ key: 'list_name', label: 'Lists' },
					{ key: 'store_type', label: 'Email Service' },
					{ key: 'total_contacts', label: 'Contacts' },
					{ key: 'created_date', label: 'Date Created' },
					{ key: 'actions' },
				],
				contactListArray: [],	
			}
		},
		watch: {
			exportContactListObject(newVal) {
				return newVal;
			}
		},
		created() {
			this.getContactList();
		},
		computed: {
		},
		methods: {
			editContactList(contactList) {
				this.editContactListObject = contactList;
				this.isAddNewUserSidebarActive = true;
			},
			addContactList() {
				this.editContactListObject = {};
				this.editContactListObject.list_name = '';
                this.editContactListObject.store_type = 1; /* In our site */
                this.editContactListObject.drip_account_id = '';
                this.editContactListObject.drip_campaign_id = '';
				this.isAddNewUserSidebarActive = true;
			},
			deleteContactList(contactListId) {
				this.$swal({
					title: 'Are you sure?',
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, delete it!',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1',
					},
					buttonsStyling: false,
				}).then(result => {

					if (result.value) {

						this.$contactAPI.delete({
							'auth_id': this.$root.authUserObject.id ?? '',
							'contact_list_id': contactListId ?? '',
						}).then((response) => {
							this.getContactList();
							response = response.data;
							if (response.status) {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'success',
										text: response.message,
									},
								});
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'danger',
										text: response.message,
									},
								});
							}
						});
					}
				})
			},
			getContactList() {
				this.isFetchingContactListArray = true;
				this.$contactAPI.get('fetch-contact-list', {
					'auth_id': this.$root.authUserObject.id ?? '',
				}).then((response) => {
					response = response.data;
					this.contactListArray = response.contact_list_array;
					this.isFetchingDripCampaign = 0;
					this.isFetchingContactListArray = false;

					response.contact_list_array.forEach(contactObj => {
						this.fetchTotalContactCount(contactObj);
					});
					
				});
			},
			fetchTotalContactCount(contactObj) {
				this.$contactAPI.post('fetch-contact-count', {
					'contact_list_id': contactObj.contact_list_id ?? '',
					'store_type': contactObj.store_type ?? '',
					'drip_account_id': contactObj.drip_account_id ?? '',
					'drip_campaign_id': contactObj.drip_campaign_id ?? '',
					'contact_type': 'contact'
				}).then((response) => {
					if (response.status) {
						response = response.data;
						contactObj.total_contacts = response.total_contacts;
						contactObj.drip_campaign_name = response.drip_campaign_name;
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
				});
			},
			openExportSubscribersModal(contactList) {
				this.exportContactListObject = contactList;
				this.$bvModal.show('modal-export-subscribers');
				this.isExportSubscribersRequestActive = true;
			},
			hideModalExportSubscribers(event) {
				this.exportContactListObject = {};
			}
		},
		setup() {
			const USER_APP_STORE_MODULE_NAME = 'app-user'

			// Register module
			if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

			// UnRegister on leave
			onUnmounted(() => {
				if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
			})

			const {
				// UI
				resolveUserRoleVariant,
				resolveUserRoleIcon,
				resolveUserStatusVariant,
			} = useUsersList()

			return {
				// UI
				resolveUserRoleVariant,
				resolveUserRoleIcon,
				resolveUserStatusVariant,
			}
		},
	}
</script>

<style lang="scss" scoped>
	.per-page-selector {
		width: 90px;
	}
</style>